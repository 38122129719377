.Auctions {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    max-width: 90%;
}

.Auctions .DataReturnDisplay {
    grid-column-start: 1;
    grid-column-end: span 2;
}

.Auctions .AuctionHistorySelector label {
    display: block;
}

.PriceSummary {
    display: grid;
    padding-bottom: 10px;
    grid-template-columns: 9em 1fr;
    grid-template-rows: auto;
}

.PriceSummary .PriceSummaryTitle {
    display: block;
    font-weight: bold;
    grid-column-start: 1;
    grid-column-end: span 2;
}

.PriceSummary .PriceSummarySection {
    height: fit-content;
}

.PriceChart {
    margin-top: 3em;
    display: block;
}

.PriceChart th {
    text-align: left;
}

.Auctions .RegionSelector div {
 display: inline-block;
 width: 75px;
}

.Auctions .RegionSelector div span {
    background-color: white;
    width: 75px;
    display: inline-block;
}

.Auctions .RegionSelector div li {
    background-color: #42A86B;
    width: 75%;
    padding: 10px;
}

.Auctions .RegionSelector div li:hover {
    background-color: rgb(46, 116, 69);
}

.DataReturnDisplay .GChart {
    margin-top: 2em;
}

.Auctions .PriceChartTitle {
    font-weight: bold;
}