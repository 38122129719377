.Status {
    font-size: 1.4em;
    font-family: monospace;
}

.RawResult pre {
    text-align: left;
    font-family: monospace;
    font-size: 1em;
}

.WebResult {
    text-align: left;
}

.RunResultCore {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

@media only screen and (max-width: 900px) {
    .RunResultCore {
        grid-template-columns: 1fr;
        
        font-size: .25em;
    }
}

.Bonuses {
    background-color: green;
}

.RecipeListing {
    display: block;
    padding-left: 25px;
    background-color: #3FB5B0;
}

.RecipeCost {
    font-size: 1em;
}

.RunResultItemRecipes .VendorItemPrice,
.AHItemPrice {
    font-size: 1em;
}

.RunResultItemRecipes {
    background-color: #359690;
}

.RunResultItemRecipesHeader {
    font-size: 1.4em;
    padding-bottom: 5px;
}

.RecipeListing .RunResultItemRecipesHeader {
    font-size: 1.2em;
    padding-bottom: 5px;
}

.RecipeHeader {
    font-size: 1.2em;
}

.RecipeHeader .RecipeHeaderDetails {
    display: block;
}

.RunResultItemRecipesChildren {}

.HideableChild {
    overflow: hidden;
    max-height: 10000px;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: ease-in-out;
}

.HiddenChild {
    max-height: 0px;
}

.RecipeBanner,
.ItemBanner,
.RecipePartsBanner,
.ItemRecipesBanner {
    display: block;
    font-family: sans-serif;
    font-variant-caps: all-petite-caps;
    cursor: pointer;
}

.RunResultDisplay {
    display: block;
}