.ShoppingListItem {
    display: grid;
    grid-template-columns: 50px 1fr;
    column-gap: 1em;
}

.ShoppingListItem .ShoppingListColumn{
    display: block;
}

.ShoppingListItem .Quantity {
    vertical-align: top;
    text-align: right;
    font-family: monospace;
}

.ShoppingListItem .Data .VendorItemPrice,.AHItemPrice {
    font-size: 10pt;
}

.ShoppingList {
    padding-left:20px;
}

.ShoppingLists {
    display: block;
    float: right;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ShoppingListsHeader {
    font-weight: bold;
    font-size: 14pt;
    display: block;;
}

.ShoppingListTitle {
    font-weight: bold;
    display: block;
    clear: left;
}

.ShoppingLists ul {
    list-style: none;
    margin-left: 0px;
    margin-top: 5px;
    padding-left: 0px;
}