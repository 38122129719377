.HelpBox {
    display: block;
    text-align: left;
    max-width: 750px;
    align-self: start;
    margin-top: 2em;
}

.HelpBox .HelpHeader {
    font-weight: bold;
    font-variant: small-caps;
}

.HelpBox .HelpTop {
    font-weight: bolder;
    display: block;
    margin-bottom: 5pt;
    cursor: help;
}

.HelpBox p {
    font-size: small;
}