.RunCoordinator {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

form.TypePicker {
    display: block;
}

.TypePicker label {
    font-size: 10pt;
    padding-right: 25pt;
}