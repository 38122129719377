.App {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App .Header {
  width: 100%;
  background-color: #001f00;
  display: flex;
  flex-direction: row;
  min-height: 4em;
  align-items: center;
  column-gap: 2em;
  justify-content: flex-start;
}

.App .Header H1 {
  font-family: sans-serif;
  font-size: 2em;
  color: #42A86B;
  margin: 0px;
}

.App .Nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  border-radius: 2em;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.App .Nav ul li {
  float: left;
}

.App .Nav ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.App .Nav ul li a:hover {
  background-color: #111;
}

.App .MainHolder {
  display: flex;
  justify-content: center;
  background-color: #B0F5CC;
}

.App .MainHolder .Main {
  width: 95%;
  background-color: #B0F5CC;
}

@media only screen and (max-width: 700px) {
  .App .Header {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1em;
  }

  .App .Nav ul {
    flex-direction: column;
  }

  .App .MainHolder .Main {
    max-width: 98%;
  }
}